import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom"
import { useGet } from "state/jotai";
import { currentActiveProject, current_language, params_query } from "state/store.global";

export const useClick = () => {
    const currentLanguage  = useAtom(current_language)[0];
    const setActiveProject = useAtom(currentActiveProject)[1];
    const navigate         = useNavigate()
    const params           = useGet(params_query);

  const click = e => {
    if ( typeof e?.Presentation === 'string' ) {
        setActiveProject(e?.Presentation);
        navigate(`/${currentLanguage}/isometry/${e?.Presentation}${params.length ? params : ''}`)
    }
  }

  return click
}