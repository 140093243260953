import { Box, Button, Divider, Drawer, FormControl, FormControlLabel, Grid, IconButton, MenuItem, OutlinedInput, Radio, RadioGroup, useMediaQuery } from "@mui/material";
import { Icon } from "components/Icons";
import React, { useEffect, useState } from "react";
import ContactForm from "./ContactForm";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useBookViewingDrawer } from "./useBookViewingDrawer";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { GETLEADTOKEN } from "api/api";
import { CustomInput, CustomLabel, CustomSelect, PlaceHolderInputField  } from "./styled-components";
import { useFetch } from "hooks/fetch/useFetch";
import axios from "axios";
import { motion } from "framer-motion";
import SuccessMessage from "./SuccessMessage";
import { useOpenHouseData } from "components/OpenHouseBanner/OpenHouseBanner.Data";
import { PhoneNumberDropdown } from "components/AdvanceDropdown/PhoneNumberDropdown/Dropdown";
import { ReactSelect } from "components/AdvanceDropdown/NormalDropdown/Dropdown";
import { CheckBox } from "components/FormComponent/CheckBox";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { useLocation } from "react-router-dom";
import { SearchDropdown } from "components/AdvanceDropdown/SeachDropdown/Dropdown";
import { CustomPlaceHolderInputField } from "components/FormComponent/TextField";
import { CustomButton } from "components/Unit/NewReservationButton";
import { enqueueSnackbar } from "notistack";
import EusButton from "components/Button/EusButton";
import { useBreak } from "hooks/useBreak";

const BookViewingDrawer = () => {
  const here = useLocation().pathname;
  const atUnit = here.includes("unit");
  
 
  const { isOpen, toggle, unit } = useBookViewingDrawer();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { i18n: { language } } = useTranslation();
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const isTab = useBreak('md_dn')

  const campaignIds = {
    "7,11": '8d15326c57c1e4794ac2b883891bc9d5',
    "7": '6ed309a71f93590bca0d3d2a92d5d7ac',
    "11": 'd35be751f9f014a4189ecb2744beb89e'
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  return (
     <Drawer
     hideBackdrop={true}
     anchor={isMobile ? "bottom" : "right"}
     open={isOpen}
     onClose={() => toggle()}
     PaperProps={{ sx: { scrollbarWidth: "none", "&::-webkit-scrollbar": { display: "none" }, color: "secondary.contrastText", backgroundColor: "secondary.main", height: { lg: `calc(100% - 162px - ${atUnit || isMobile ? "-90px" : "2px"})` }, top: `${isMobile ? "56px" : isTab ? '56px' : "70px"}`, justifyContent: "space-between", boxShadow: 'unset', paddingBottom: { xs:"16px", lg: 0 } } }}
     sx={{  "&.MuiDrawer-root": { width: 450, top: "70px", right: 0, left: 'unset', bottom: 'unset' } }}
     >
       <Box sx={{ width: isMobile ? "auto" : 450, position: "relative", pt: 4, pb: 4, px: 3, lineHeight: 1.3 }}>
         <IconButton
           onClick={toggle}
           sx={{
             position: "absolute",
             right: 16,
             top: 16,
           }}
         >
            <span className="icon-hover-effect" >
           <Icon
             icon="close"
             isFaIcon={true}
             size={"lg"}
             sx={{
               fontSize: 18,
             }}
           />
           </span>
         </IconButton>
             
        <Box sx={{ mt: 5 }}>
        <ContactForm unit={unit} campaignId={campaignIds?.[unit?.data?.Data?.ProjectID?.Value]} drawerType = {'bookViewingDrawer'}>
           <Box sx={{ fontWeight: 700, lineHeight: 1.4, mb: 3 }}>
             {
               buttonsLabel?.filter(
                 (btn) =>
                   btn?.UILabelProperty === "footerHeading1"
               )[0]?.Label
             }
             <br />
             <br />
             <span
               style={{
                 fontSize: "0.95em",
                 fontWeight: "normal",
               }}
             >
               {
                 buttonsLabel?.filter(
                   (btn) =>
                     btn.UILabelProperty === "BookShowingHeading"
                 )[0]?.Label
               }
             </span>
           </Box>
         </ContactForm>
        </Box>
       </Box>
       <PoweredBy padding={ isMobile ? "0 0 60px 0" : '16px 0 16px 0'} clr="#FFF"/>
     </Drawer>
  );
};

export default BookViewingDrawer;


{/* <SuccessMessage label={false} msg={language === "da" ? <p>Vi har registreret dit ønske om at bestille en fremvisning.<br/> Du vil blive kontaktet af vores mægler for at aftale nærmere.</p> : <p>We have registered your wish to order a screening. <br />You will be contacted by our broker to make further arrangements</p>} width={422}/> */}