import { atom, useAtom } from "jotai";

const isBookViewingDrawer = atom(false);
const BookViewingDrawerUnit = atom(null);

const useBookViewingDrawer = () => {
  const [isOpen, setIsOpen] = useAtom(isBookViewingDrawer);
  const [unit, setUnit] = useAtom(BookViewingDrawerUnit);

  const toggle = () => {
    if (isOpen) {
      // set null if closed.
      setUnit(null);
    }

    setIsOpen(!isOpen);
  };

  const open = (unit) => {
    setUnit(unit);
    setIsOpen(true);
  };

  return { toggle, open, isOpen, unit };
};

export { useBookViewingDrawer };
