import { Box, Typography, } from "@mui/material";
import ContactForm from "components/Contact/ContactForm";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useLocation } from "react-router-dom";

function SignUpForm({ unit }) {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const here         = useLocation().pathname
  const atUnit       = here.includes('unit')
  
  const campaignIds = {
    "7,11": '8d15326c57c1e4794ac2b883891bc9d5',
    "7": '6ed309a71f93590bca0d3d2a92d5d7ac',
    "11": 'd35be751f9f014a4189ecb2744beb89e'
  }

  return (
    <Box id="unit_page_contact_form" sx={{ pt: {xs: 3, lg: 5}, pb: { md: 5, xs: 0 }, px: { xs: 3, lg: '45px' } }} >
    <Box sx={{  maxWidth: 514, mx: "auto", }} >
      <ContactForm campaignId={campaignIds?.[unit?.data?.Data?.ProjectID?.Value]} drawerType = {'bookViewingDrawer'} grid unit={unit}>
        <Box sx={{ mb: 3 }} >
          <Box className="h3">
            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'SidefodformularOverskrift')[0]?.Label } */}
            {/* Tilmelding til interesselisten */}
           { buttonsLabel?.filter( btn => btn.UILabelProperty ===  "footerHeading1")[0]?.Label }
          </Box>
          <br />
          <Box className="paragraph1-regular" >
          {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'SidefodformularUnderoverskrift')[0]?.Label } */}
          {/* Vil du gerne komme først i køen til at modtage nyheder og opdateringer? Udfyld formularen nedenfor for at blive tilføjet til interesselisten */}
          { buttonsLabel?.filter( btn => btn.UILabelProperty ===  "BookShowingHeading")[0]?.Label }
          </Box>
        </Box>
      </ContactForm>
    </Box>
  </Box>
  );
}

SignUpForm.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default SignUpForm;
