import React, { useEffect } from "react";

export const Recaptcha = ({ display = "none", position = "fixed", bottom = "14px", right = "-186px" }) => {
  useEffect(() => {
    // Ensure the badge visibility changes only after the component mounts
    const recaptchaDiv = document.querySelector(".grecaptcha-badge");
    if (recaptchaDiv) {
         // Wrap the reCAPTCHA badge inside a custom container
      const container = document.querySelector(".recaptcha-container");

      if (container) {
        container.appendChild(recaptchaDiv);

        
        recaptchaDiv.style.display = display;
        recaptchaDiv.style.position = position;
        recaptchaDiv.style.bottom = bottom;
        recaptchaDiv.style.right = position;
      }
    }

    return () => {
      // Reset badge visibility when the component unmounts
      if (recaptchaDiv) {
        recaptchaDiv.style.display = "none"; // Default Google reCAPTCHA visibility
        recaptchaDiv.style.position = "fixed";
        recaptchaDiv.style.bottom = "14px";
        recaptchaDiv.style.right = 'right';
      }
    };
  }, []);

  return <div className="recaptcha-container"></div>; // No visual output needed, this component controls the badge via styles
};
