import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { Main, Section } from "./SelectFunction.style";
import { Box } from "@mui/material";
import { Icon } from "assets/Icons_";
import { getFocus, useGet } from "state/jotai";
import { active_project, vis_showOldSlider } from "state/store.global";
import { ShiftProperty } from "components/ShiftProperty/ShiftProperty";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { _bootstrap } from "state/store.bootstrap";
import { useBreak } from "hooks/useBreak";
import { getIsoData, getLowIsoData } from "state/jotai.isometryDataHooks";
import { Status } from "components/StatusPopup/StatusPopup";
import { _project } from "state/store.projects";
import { DesktopColorLegend } from "components/ColorLegend/DesktopColorLegend";
import { ColorLegend } from "components/ColorLegend/ColorLegend";
import { FooterButton } from "components/DualMenu/DualMenu.style";
import { ETStyledButton } from "components/Button/EusButton";


export const SelectFunction = ({showSelectFunction, setShowSelectFunction,rotatemode,isrotate,click1,click2,turnBuilding}) => {
  const [showProperty, setShowProperty] = useState(false);
 
  const { pin, pid } = useGet(active_project);
 
  const config            = getIsoData('config');
  const showOldSlider     = useGet(vis_showOldSlider);
  const here              = useLocation().pathname;
  const atModel           = here.includes("isometry");
  const projectType       = getFocus(_bootstrap, "type");
  const isMobile          = useBreak("md_dn");
  const [state, setState] = useState(false);
  const percent           = getIsoData("percent");

  const lowQualityPercent = getLowIsoData('percent')
  
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const showButton = [
    "list",
    "compare",
    "favorites",
    "gallery",
    "downloads",
  ].some((i) => here.includes(i));

  const animation = {
    initial   : { opacity: 0 },
    animate   : { opacity: 1 },
    exit      : { opacity: 0 },
    transition: { duration: 0.5, ease: "linear" },
  };

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {
      <ShiftProperty key='select-function-shift-property'
                          showProperty    = {showProperty}
                          setShowProperty = {setShowProperty}
                          setShowSelectFunction={setShowSelectFunction}
                      />
        }
        {!state && showSelectFunction && (
          <>
            <Main isMobile={isMobile} as = {motion.div} {...animation} clr = {clr}>
              <Box
                sx={{
                  color         : "#fff",
                  borderBottom  : `1px solid ${clr.primary_text}`,
                  display       : "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box  sx        = {{ display:"flex", flexDirection:"column", justifyContent:"center", width: "100%", padding:  "16px 16px 16px 48px" }}>
                <Box  sx        = {{ display: "flex", justifyContent: "center",  color: clr?.primary_text }}>
                <span className = "h5"> { buttonsLabel?.filter( btn => btn.UILabelProperty === "VælgFunktion")[0]?.Label }</span>
                  </Box>
                </Box>
                <Box
                className="icon-hover-effect"
                  sx      = {{ padding: "16px" }}
                  onClick = {(e) => {
                    // e.stopPropagation()
                    const buttons = document.querySelectorAll('button, div[role="button"]');
                      buttons.forEach(button => button.blur());
                    setTimeout(() => {
                      setShowSelectFunction(false)
                    
                    }, 100);
                 
                  }}
                
                >
                   
                  <Icon icon = {"close"} size = "xl" isFaIcon = {true} color = {clr?.primary_text } skipColorChange={true}/>
                </Box>
              </Box>
              <Box sx = {{padding:"16px" }}>
              <Box sx = {{display:"flex", flexDirection:"column", gap:"16px"}}>
                {
                  isMobile && !rotatemode &&
                  <>
                  { 
                    <ETStyledButton
                    variant  = {"secondary-1st-varient"}
                    tabIndex = {"0"}
                    role     = "button"
                    pin      = {pin}
                    onClick  = {(e) => {
                      // e.stopPropagation()
                     
                          setShowProperty(true);
                     
                          setShowSelectFunction(false);
                            // toggleDrawer();
                      }}
                      clr        = {clr}
                      showButton = {showButton}
                      >
                      <Icon
                          icon     = "buildings"
                          size     = "lg"
                          color    = {pin > 0 || showButton ? "#000" : "#000"}
                          isFaIcon = {true}
                          skipColorChange={true}
                      />
                      <div className = "eus-button-2"  style = {{ color: "#000000" }}>
                            Skift ejendom
                        </div>
                    
                    </ETStyledButton>
                  }
                   
                   {/* commented for faelldby project */}
                  {/* { projectType === "poly" && pin !== 0  && (
                  <ETStyledButton
                  tabIndex = {"0"}
                  role     = "button"
                  variant  = {"secondary-1st-varient"}
                  isMobile = {isMobile}
                  pin      = {pin}
                  clr      = {clr}
                  onClick={()=> isMobile && setState(true)}
                  >
                    <Status
                      pin      = {pin}
                      state    = {state}
                      setState = {setState}
                      isMobile = {isMobile}
                      {...{ percent: lowQualityPercent }}
                    />
                  </ETStyledButton>
                )} */}
                  </>
              }
              {config?.IMAGE_SNAPFRAMES?.length > 0 &&
                      atModel &&
                      !showOldSlider && (
                <ETStyledButton
                  variant  = {"secondary-1st-varient"}
                  // tabIndex = {"0"}
                  // role     = "button"
                  pin      = {pin}
                  clr     = {clr}
                  onClick = {rotatemode && isrotate ? click1 : click2}
                >
                  <>
                    {!turnBuilding ? (
                      <>
                        <Icon
                          icon     = "rightarrowrotate"
                          color    = {pin > 0 || showButton ? "#000" : "#000"}
                          isFaIcon = {true}
                          skipColorChange={true}
                          size     = "lg"
                        />{" "}
                        <div
                          className = "eus-button-2"
                          style     = {{ color: "#000" }}
                        >
                          {
                            buttonsLabel?.filter(
                              (btn) => 
                                btn?.UILabelProperty === "Drejbygning"
                            )[0]?.Label
                          }
                        </div>
                      </>
                    ) : (
                      <>
                        <Icon
                       
                          icon     = {isrotate ? "pause" : "play"}
                          color    = {"#000"}
                          isFaIcon = {true}
                          skipColorChange={true}
                          size     = "lg"
                        />{" "}
                        <p style = {{ color: "#000" }}>
                          {isrotate
                            ? "Stop rotation"
                            : "Start rotation"}
                        </p>
                      </>
                    )}
                  </>
                </ETStyledButton>
              )}
              
              { projectType === "mono" && atModel && (
                <Section
                  isMobile = {isMobile}
                  pin      = {pin}
                  clr      = {clr}
                >
                  <Status
                    pin      = {pin}
                    state    = {state}
                    setState = {setState}
                    isMobile = {isMobile}
                    {...{ percent: lowQualityPercent }}
                  />
                </Section>
              )}
              </Box>
              </Box>
            </Main>
          </>
        )}
        { 
        isMobile && state && (
          <>
            <ColorLegend setState = {setState}/>
          </>
        )
      }
      </AnimatePresence>
    </>
  );
};
